import { React, useEffect } from 'react'
import style from '../styles/ShippingPolicy.module.scss';

function ShippingPolicy() {

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className={style.shippingPolicyDiv}>
      <h2>Shipping Policy</h2>
      <p>If a delivery to your address fails to arrive, gets lost, or is stolen (or any other case), we will attempt to re-ship the order to you once at our own expense.</p>
      <p>In this case, we prefer to ship to a secondary address, if possible, and will contact you to ask for one.</p>
      <p>If the second delivery fails, we will issue a refund for your order.</p>
    </div>
  )
}

export default ShippingPolicy;