import React from 'react';
import style from '../styles/HeroSection.module.scss';
import { Link } from 'react-router-dom';

function HeroSection() {
  return (
    <section className={style.heroSection}>
      <div className={style.heroSectionOverlay}>&nbsp;</div>
      <div className={style.heroSectionText}>
        <h1 className={style.heroSectionMainText}>Out of this World Apparel</h1>
        <h5 className={style.heroSectionSubText}>Check out our selection!</h5>
        <Link to='/store/all'><button className={style.heroSectionButton}>Shop Here</button></Link>
      </div>
    </section>
  )
}

export default HeroSection;