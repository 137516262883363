import React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import MerchItem from '../components/MerchItem';
import style from '../styles/StorePage.module.scss';
import supabase from '../config/supabaseClient';
import { color } from 'framer-motion';

function StorePage() {
  const [backendData, setBackendData] = useState([]);
  const [backEndDataBackup, setBackendDataBackup] = useState([]);
  const [isFilterPressed, setIsFilterPressed] = useState(false);
  const [colorsArray, setColorsArray] = useState([]);
  let { storeCategory } = useParams();  

  useEffect(() => {
    window.scrollTo(0, 0);
    getShop(storeCategory);
  }, []);

  function getColors(data) {
    // Pull StorePage data, get unique primaryColor and secondaryColor and put into an array
    let colorArray = [];
    // Cycle through array, grab primary_color and secondary_color if it's not NULL
    for(let productIterator = 0; productIterator < data.length; productIterator++) {
      if(colorArray.includes(data[productIterator].primary_color) === false && data[productIterator].primary_color !== null) {
        colorArray.push(data[productIterator].primary_color);
      }
      else if(colorArray.includes(data[productIterator].secondary_color) === false && data[productIterator].secondary_color !== null) {
        colorArray.push(data[productIterator].secondary_color);
      }
    }
    colorArray.sort();
    setColorsArray(colorArray);
  }

  async function getShop(storeCategory) {
    switch(storeCategory) {
      case 'all': 
        var { data, error }  = await supabase
        .from('store')
        .select()
        .order('product_name', { ascending: true });
        setBackendData(data);
        setBackendDataBackup(data);
        break;
      case 'shirts':
        var { data, error }  = await supabase
        .from('store')
        .select()
        .eq('department', 'Shirt')
        setBackendData(data);
        setBackendDataBackup(data);
        break;
      case 'accessories':
        var { data, error }  = await supabase
        .from('store')
        .select()
        .eq('department', 'Accessories')
        setBackendData(data);
        setBackendDataBackup(data);
        break;
      case 'shoes':
        var { data, error }  = await supabase
        .from('store')
        .select()
        .eq('department', 'Shoes')
        setBackendData(data);
        setBackendDataBackup(data);
        break;
      default: 
        var { data, error }  = await supabase
        .from('store')
        .select()
        .order('product_name', { ascending: true });
        setBackendData(data);
        setBackendDataBackup(data);
        break;
    }
    await getColors(data);
  }

  function searchBasedOnText(e) {
    let wordEntered = e.target.value;
    if(wordEntered === '') {
      setBackendData(backEndDataBackup);
    } 
    else if(e.key === 'Enter') {
      e.target.blur();
    } else {
      let seachedStoreItems = backEndDataBackup.filter((item) => item.product_name.toLowerCase().includes(wordEntered.toLowerCase()) === true  
      || item.brand.toLowerCase().includes(wordEntered.toLowerCase()) === true);
      setBackendData(seachedStoreItems);
    }
  }

function searchBasedOnColor(e) {
  // If color is not checked, check it
  // Else uncheck and set store to backupBackendData
  console.log(backEndDataBackup);
  let colorFilterEntered = e.target.value;
  let filteredStoreItems = backEndDataBackup.filter((item) => 
    item.primary_color.toLowerCase().includes(colorFilterEntered.toLowerCase()) === true
  || (item.secondary_color !== null && item.secondary_color.toLowerCase().includes(colorFilterEntered.toLowerCase()) === true)); // WHY U NO WORK???
  console.log(filteredStoreItems); 
  setBackendData(filteredStoreItems);
}

function turnOffFilterWindow() {
  setIsFilterPressed(false);
}

function resetFilter() {
  setBackendData(backEndDataBackup);
}

  return (
    <div>
      <div className={style.shopSearchBar}>
        <div className={style.shopSearchButtons}>
          <div className={style.shopSearchDiv}>
            <button className={style.shopSearchButton}></button>
            <input onKeyDown={(e) => searchBasedOnText(e)}></input>
          </div>
          <div className={style.shopFilterDiv}>
            <button className={style.shopFilterButton} onClick={() => setIsFilterPressed(!isFilterPressed)}></button>
            { isFilterPressed && (
              <div className={style.shopFilterButtonOptions}>
                <h2>Colors</h2>
                <button onClick={() => resetFilter()}>Reset Filter</button>
                <ul className={style.shopFilterButtonList}>
                  {
                    colorsArray.map(item => {
                      return (
                        <li className={style.shopFilterListItem} key={item}>
                          <input onClick={(e) => searchBasedOnColor(e)}  type='radio' id={item.toLowerCase()} name='item_color' value={item}/>
                          <label htmlFor={item.toLowerCase()}>{item}</label>
                        </li>
                      );
                    })
                  }
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      <section onClick={() => turnOffFilterWindow()} className={style.storePageItemsSection}>
        {
          backendData.map(item => (
            <MerchItem key={item.product_id} storeItemObject={item}></MerchItem>
          ))
        }
      </section>
    </div>
  )
}

export default StorePage;