import React from 'react';
import style from '../styles/EmailSubscription.module.scss';

function EmailSubscription() {
  return (
    <div className={style.subscriberMainSection}>
      <div className={style.subscribeSection}>
        <h3>SUBSCRIBE TO OUR NEWSLETTER</h3>
        <p>Sign up for our mailing list and stay up to date with store updates!</p>
        <form className={style.subscribeForm}>
          <input className={style.subscriberInput} type="email" placeholder='Your E-mail' required></input>
          <button className={style.emailSignUpButton}>Sign Up</button>
        </form>
      </div>
    </div>
    
  )
}

export default EmailSubscription;