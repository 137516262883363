import React, { useEffect, useState } from 'react';
import { v4 as uuidv4} from 'uuid';

import companyLogo from '../assets/icons/star-fiend-logo.png';
import style from '../styles/Checkout.module.scss';
import googleLogo from '../assets/icons/google-logo.png';
import venmoLogo from '../assets/icons/venmo-logo-white.png';
function Checkout(props) {

  const [cardExpDate, setCardExpDate] = useState();
  const { currentCart } = props;
  const [cartCost, setCartCost] = useState(0.00);
  const [shippingCost, setShippingCost] = useState(0.00);
  const [totalCost, setTotalCost] = useState(0.00);

  useEffect(() => {
    calculatePreShippingCartTotal();
    calculateShipping();
    calculateTotal();
  });

  function processCardExpirationDate(e) {
    let cardExpDate = e.target.value;
    if(cardExpDate.length <= 2) {
      setCardExpDate(cardExpDate + '/');
    }
    else {
      setCardExpDate(`${cardExpDate.charAt(0)}${cardExpDate.charAt(1)} / ${cardExpDate.charAt(2)}${cardExpDate.charAt(3)}`);
    }
  }

  function calculateTotalForSingleItem(itemPrice, itemAmount) {
    let currentItemRunningTotal = itemPrice * itemAmount;
    currentItemRunningTotal = Math.round(currentItemRunningTotal * 100) / 100;
    return currentItemRunningTotal;
  }

  function calculatePreShippingCartTotal() {
    let currentCartRunningTotal = 0.00;
    for(let currCartLooper = 0; currCartLooper < currentCart.length; currCartLooper++) {
      currentCartRunningTotal = currentCartRunningTotal + (currentCart[currCartLooper].product_price * currentCart[currCartLooper].product_amount);
    }
    currentCartRunningTotal = Math.round(currentCartRunningTotal * 100) / 100;
    setCartCost(currentCartRunningTotal);
  }

  function calculateShipping() {
    let unroundedShippingCost = cartCost * 0.10;
    //unroundedShippingCost = ((unroundedShippingCost * 100) / 100).toFixed(2);
    unroundedShippingCost = Math.round(unroundedShippingCost * 100) / 100;
    unroundedShippingCost = unroundedShippingCost.toFixed(2);
    setShippingCost(unroundedShippingCost);
  }

  function calculateTotal() {
    let total = cartCost + parseFloat(shippingCost);
    total = Math.round(total * 100) / 100;
    setTotalCost(total);
  }
  
  let customerCartMap = currentCart.map(item => (
    <li className={style.checkoutCartItem} key={uuidv4()} >
      <div className={style.checkoutCartItemDetails}>
        <div className={style.checkoutCartItemImageAndAmountDiv}>
          <img className={style.cartItemImage} src={process.env.PUBLIC_URL + `/assets/images/${item.product_filename}`} alt={item.product_name}/>
          <p>{item.product_amount}</p>
        </div>
        <div>
          <p>{item.product_name}</p>
          <p>{item.product_size}</p>
        </div>
        
      </div>
      <p>{`$${calculateTotalForSingleItem(item.product_price, item.product_amount)}`}</p>
    </li>
  ));
  
  return (
    <main className={style.checkoutMain}>
      <section>
        <div className={style.companyCheckoutBar}>
          <img className={style.companyCheckoutLogo} src={companyLogo} alt='Star Fiend Company Logo'></img>
          <h3>Star Fiend Studios</h3>
        </div>
        <section>
          <h4>Express Checkout</h4>
          <div className={style.expressCheckoutButtonsDiv}>
            <button className={`${style.expressCheckoutButton} ${style.expressCheckoutPayPalButton}`}>
              <p className={style.expressCheckoutPayPalButtonTextOne}>Pay</p>
              <p className={style.expressCheckoutPayPalButtonTextTwo}>Pal</p>
            </button>
            <button className={`${style.expressCheckoutButton} ${style.expressCheckoutGoogleButton}`}>
              <img className={style.expressCheckoutGoogleButtonImage} src={googleLogo} alt='Google Logo'/>
              <p>Pay</p>
            </button>
            <button className={`${style.expressCheckoutButton} ${style.expressCheckoutVenmoButton}`}>
              <img className={style.expressCheckoutVenmoButtonImage} src={venmoLogo} alt='Venmo Logo'/>
            </button>
          </div>
        </section>
        <div className={style.checkoutDivider}>
            <hr></hr>
            <p className={style.checkoutDividerText}>OR</p>
            <hr></hr>
        </div>
        <section className={style.checkoutSection}>
          <h4>Contact Information</h4>
          <input className={style.checkoutInput} type='email' placeholder='Email Address'></input>
        </section>
        <section className={style.checkoutSection}>
          <h4>Shipping Address</h4>
          <div className={style.checkoutInputSingleLine}>
            <input type='text' placeholder='First Name'></input>
            <input type='text' placeholder='Last Name'></input>
          </div>
          <input type='text' placeholder='Company (optional)'></input>
          <input type='text' placeholder='Street Address'></input>
          <input type='text' placeholder='Apartment (optional)'></input>
          <div className={style.checkoutInputSingleLine}>
            <input type='text' placeholder='City'></input>
            <input type='text' placeholder='State'></input>
          </div>
          <input type='number' pattern='[0-9]*' inputMode='numeric' placeholder='Phone Number (optional)'></input>
        </section>
        <section className={style.checkoutSection}>
          <h4>Payment Information</h4>
          <input type='number' pattern='[0-9]*' inputMode='numeric' placeholder='Card Number' maxLength='19'></input>
          <input onChange={(e) => processCardExpirationDate(e)} type='text' pattern='[0-9]*' inputmode='numeric' placeholder='Expiration date (MM / YY)' maxLength='4'></input>
          <input type='text' pattern='[0-9]*' inputMode='numeric' placeholder='Security code' maxLength='4'></input>
          <input placeholder='Name on card'></input>
        </section>
      </section>
      <section className={`${style.checkoutSection} ${style.checkoutCostsSection}`}>
        <h4>Order Summary</h4>
        <ul className={style.checkoutCartList}>
          {customerCartMap}
        </ul>
        <form className={style.checkoutDiscountGiftCardForm}>
          <input type='text' placeholder='Discount code or gift card'></input>
          <button className={style.discountGiftCardButton}>Apply</button>
        </form>
        <section className={style.totalCostSection}>
          <div className={style.totalCostDiv}>
            <p>Subtotal:</p> 
            <p>$ {cartCost}</p>
          </div>
          <div className={style.totalCostDiv}>
            <p>Shipping:</p> 
            <p>$ {shippingCost}</p>
          </div>
          <div className={style.totalCostDiv}>
            <p>Total Cost:</p> 
            <p>$ {totalCost}</p>
          </div>
          <button className={style.checkoutPayNowButton}>Pay Now</button>
        </section>
        
      </section>

    </main>
  )
}

export default Checkout;