import React from 'react';
import { useState, useEffect } from 'react';
import style from '../styles/Cart.module.scss';
import { v4 as uuidv4} from 'uuid';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from "framer-motion";

function Cart(props) {
  let { currentCart, setCurrentCart } = props;
  let [currentCartCost, setCurrentCartCost] = useState(0.00);

  useEffect(() => {
    window.scrollTo(0, 0);
    calculateTotal();
  });

  function checkIfCartHasItems() {
    if(currentCart.length < 1) {
      // Cart is empty
      return (
          <div className={style.emptyCartMain}>
            <div className={style.cartEmptyCheckoutDiv}>
              <p>Your cart is empty</p>
              <Link to='/store/all'><button className={style.cartCheckoutButton}>START SHOPPING</button></Link>
            </div>
          </div>
        
      );
    }
    else {
      return (
        <main className={style.checkoutPageMain}>
          <div className={style.checkoutPageListDiv}>
              <ul className={style.cartList}>{cartMap}</ul>
          </div>
          <div className={style.cartCheckoutDiv}>
            <p className={style.subTotalPara}>{`Sub Total: $${currentCartCost}`}</p>
            <p>Taxes and shipping calculated at checkout</p>
            <Link to='/checkout'><button className={style.cartCheckoutButton}>GUEST CHECKOUT</button></Link>
          </div>
        </main>
        
      );
    }
  }

  function adjustItemAmount(cartItemId, whatOperation) {
    let updatedCart;
    for(let cartLooper = 0; cartLooper < currentCart.length; cartLooper++) {
      if(currentCart[cartLooper].product_cart_id === cartItemId) {
        if(whatOperation === '+') {
          let currentCartArray = [...currentCart];
          currentCartArray[cartLooper].product_amount = currentCartArray[cartLooper].product_amount + 1;
          updatedCart = currentCartArray
          setCurrentCart(updatedCart);
          calculateTotal();
        } else {
          let currentCartArray = [...currentCart];
          currentCartArray[cartLooper].product_amount = currentCartArray[cartLooper].product_amount - 1;
          if(currentCart[cartLooper].product_amount <= 1) {
            currentCartArray[cartLooper].product_amount = 1;
          }
          updatedCart = currentCartArray
          setCurrentCart(updatedCart);
          calculateTotal();
        }
      }
    }
  }

  function removeFromCart(cartItemId) {
    let newArray;
    newArray = currentCart.filter((item) => item.product_cart_id !== cartItemId);
    setCurrentCart(newArray);
    calculateTotal();
  }
  
  function calculateTotalForSingleItem(itemPrice, itemAmount) {
    let currentItemRunningTotal = itemPrice * itemAmount;
    currentItemRunningTotal = Math.round(currentItemRunningTotal * 100) / 100;
    return currentItemRunningTotal;
  }

  function calculateTotal() {
    let currentCartRunningTotal = 0.00;
    for(let currCartLooper = 0; currCartLooper < currentCart.length; currCartLooper++) {
      currentCartRunningTotal = currentCartRunningTotal + (currentCart[currCartLooper].product_price * currentCart[currCartLooper].product_amount);
    }
    currentCartRunningTotal = Math.round(currentCartRunningTotal * 100) / 100;
    setCurrentCartCost(currentCartRunningTotal);
  }

  let cartMap = currentCart.map(item => {
    return (
      <li className={style.cartItem} key={uuidv4()} >
        <Link to={`/store/item/${item.product_id}`}><img className={style.cartItemImage} src={process.env.PUBLIC_URL + `/assets/images/${item.product_filename}`} alt={item.product_name}/></Link>
        <div className={style.cartItemDetails}>
          <p>{item.product_name}</p>
          <p>{item.product_size}</p>
          <p>{`$${calculateTotalForSingleItem(item.product_price, item.product_amount)}`}</p>
          <div className={style.cartAmountDiv}>
            <button className={style.cartAmountDivButton} onClick={() => adjustItemAmount(item.product_cart_id, '-')}>–</button>
            <p>{item.product_amount}</p>
            <button className={style.cartAmountDivButton} onClick={() => adjustItemAmount(item.product_cart_id, '+')}>+</button>
          </div>
        </div>
        <button className={style.cartItemDeleteButton} onClick={() => removeFromCart(item.product_cart_id)}></button>
      </li>
    );
  });


  return (
    <div className={style.checkoutPage}>
      <h2>Shopping Cart</h2>
      {checkIfCartHasItems()}    
    </div>
    
  )
}

export default Cart;