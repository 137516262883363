import React from 'react';
import style from '../styles/MockWebsitePopup.module.scss';

function MockWebsitePopup(props) {

  function removePopup(e) {
    e.preventDefault();
    props.setPopupUserNotNotified(false);

  }

  return (
    <div className={style.mockupSitePopupDiv}>
      <h1>NOTICE: This is a prototype website!</h1>
      <p>This site is not a real store, it may be in the far future, but not for the forseeable future. Nevertheless, thank you for your interest!</p>
      {/*<button className={style.mockupSiteAcceptButton} onClick={() => props.setPopupUserNotNotified(false)}>I understand, but WOW you had me fooled!</button>*/}
      <button className={style.mockupSiteAcceptButton} onClick={(e) => removePopup(e)} type='button'>I understand</button>
    </div>
  )
}

export default MockWebsitePopup;