import { React, useEffect, useState } from 'react';

function Account(props) {
  const [userName, setUserName] = useState('');
  useEffect(() => {
    //setUserName(props.userName);
    window.scrollTo(0, 0);
  });

  return (
    <div>Account</div>
  )
}

export default Account;