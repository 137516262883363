import './App.scss';
import { useState, useEffect, useContext, createContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Switch, Navigate } from 'react-router-dom';
import { CookiesProvider, useCookies } from 'react-cookie';

import NavBar from './components/NavBar';
import Homepage from './pages/Homepage';
import StorePage from './pages/StorePage';
import MerchPage from './pages/MerchPage';
import Login from './pages/Login';
import Cart from './pages/Cart';
import OurCompany from './pages/OurCompany';
import Contact from './pages/Contact';
import MockWebsitePopup from './components/MockWebsitePopup';
import Footer from './components/Footer';
import ShippingPolicy from './pages/ShippingPolicy';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Account from './pages/Account';
import Checkout from './pages/Checkout';

export const UserContext = createContext();

function App() {
  const [userEmail, setUserEmail] = useState();
  let [isLoggedIn, setIsLoggedIn] = useState(false);
  let [currentCart, setCurrentCart] = useState([]);
  let [popupUserNotNotified, setPopupUserNotNotified] = useState(true);

  return (
    <div className="App">
      <Router>
        <UserContext.Provider value={[userEmail, setUserEmail]}>
          <NavBar userEmail={userEmail} currentCart={currentCart} setCurrentCart={setCurrentCart}/>
          { popupUserNotNotified && (
            <MockWebsitePopup setPopupUserNotNotified={setPopupUserNotNotified}/>
          )}
          <Routes>
            <Route exact path='/' element={<Homepage />} />
            <Route path='/store/:storeCategory' element={<StorePage />} />
            <Route path='/store/item/:productid' element={<MerchPage currentCart={currentCart} setCurrentCart={setCurrentCart}/>} />
            <Route path='/login' element={<Login />} />
            <Route path='/company' element={<OurCompany />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/cart' element={<Cart currentCart={currentCart} setCurrentCart={setCurrentCart}/>} />
            <Route path='/shippingpolicy' element={<ShippingPolicy />} />
            <Route path='/privacypolicy' element={<PrivacyPolicy />} />
            <Route path='/account/:accountname' element={<Account />} />
            <Route path='/checkout' element={<Checkout currentCart={currentCart}/>} />
            <Route path='/*' element={<Navigate to='/' />} />
          </Routes>
          <Footer/>
        </UserContext.Provider>
        
      </Router>
      
    </div>
  );
}

export default App;
