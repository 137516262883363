import { React, useEffect, useState } from 'react';
import style from '../styles/Footer.module.scss';
import { Link } from 'react-router-dom';
import EmailScription from '../components/EmailSubscription';
import companyLogo from '../assets/icons/star-fiend-logo.png';

function Footer() {
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    function updateScreenSize() {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);
  
  return (
    <footer className={style.footer}>
      <div className={style.footerSubscribeAndLinksDiv}>
        <EmailScription/>
        <div className={style.footerLinks}>
          <ul className={style.footerList}>
            <li className={style.footerListHeader}><p>Studio</p></li>
            <li><Link to='/company' className={style.footerLink}><p>About</p></Link></li>
            <li><Link to='/contact' className={style.footerLink}><p>Contact</p></Link></li>
          </ul>
          <ul className={style.footerList}>
            <li className={style.footerListHeader}><p>Explore</p></li>
            <li><Link to='/store/all' className={style.footerLink}><p>Store</p></Link></li>
          </ul>
          <ul className={style.footerList}>
            <li className={style.footerListHeader}><p>Help</p></li>
            <li><Link to='/shippingpolicy' className={style.footerLink} ><p>Shipping Policy</p></Link></li>
            <li><Link to='/privacypolicy' className={style.footerLink} ><p>Privacy Policy</p></Link></li>
          </ul>
        </div>
      </div>
      
      <div className={style.copyrightSection}>
        <p>Copyright © 2O23 Star Fiend Studios</p> {/* I put an O here instead of a zero 0 */}
        <Link to='/'><img className={style.footerCompanyLogo} src={companyLogo} alt='Star Fiend Company Logo'/></Link>
      </div>
    </footer>
  )
};

export default Footer;