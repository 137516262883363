import { React, useEffect } from 'react';
import style from '../styles/OurCompany.module.scss';
import companyLogo from '../assets/icons/star-fiend-logo.png';
import companyGroupPhoto from '../assets/images/company-group-photo-trimmed.jpg'
import screenPrintingPhoto from '../assets/images/screen-printing.jpg';
import companyPresident from '../assets/images/company-ceo-sm.jpg';

function OurCompany() {

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <main className={style.ourCompany}>
      <div className={style.companySummary}>
        <h1>We are Star Fiend Studios</h1>
        <img className={style.ourCompanyCompanyLogo} src={companyLogo} alt='Star Fiend Studios Logo'/>
        <div className={style.ourCompanySectionsDiv}>
          <h2>What We Do</h2>
          <section className={`${style.companySummarySection} ${style.companySummarySectionAltOrder}`}>
            <p>
              Founded in 2022, Star Fiend Studios' mission is to provide our customers with high quality merchandise for a competitve price. We strive to 
              make our products not only look sleek and stylish, but more importantly, last. We're proud of the products we've created and we hope that
              you'll have just as much pride wearing them! Please look forward to our official release in the future and for more products to come.
            </p>
            <img className={style.ourCompanyImg} src={companyGroupPhoto} alt='Our Employees by Naassom Azevedo on Unsplash'/>
          </section>
          <section className={style.companySummarySection}>
            <p>
              Founded in 2022, Star Fiend Studios' mission is to provide our customers with high quality merchandise for a competitve price. We strive to 
              make our products not only look sleek and stylish, but more importantly, last. We're proud of the products we've created and we hope that
              you'll have just as much pride wearing them! Please look forward to our official release in the future and for more products to come.
            </p>
            <img className={style.ourCompanyImg} src={screenPrintingPhoto} alt='Screen Printing Process by emarts emarts on Unsplash'/>
          </section>
        </div>
      </div>
      <h2>Our CEO</h2>
      <div className={style.companyStaffSection}>
        <div className={style.companyStaffMemberDiv}>
          <img className={`${style.ourCompanyImg} ${style.ourCompanyVerticalImg}`} src={companyPresident} alt='Our Company President by Andrea Piacquadio on Pexels'/>
          <p>
            Our company president, Smellvin Poggers, has been leading Star Fiend Studios since 2023, bringing in new ideas and helping the company to
            find a perfect balance between profits and sustainability.
          </p>
        </div>
      </div>
    </main>
  )
}

export default OurCompany;