import React from 'react';
import style from '../styles/MerchItem.module.scss';
import { Link } from 'react-router-dom';

function MerchItem(props) {
  const { storeItemObject } = props;
  return (
    <div className={style.merchItem}>
      <Link className={style.merchItemLink} to={`/store/item/${storeItemObject.product_id}`}>
        <img className={style.merchItemMainImg} 
          src={require(`../assets/images/${storeItemObject.file_name}`)}
          alt={storeItemObject.product_name}
          productid={storeItemObject.product_id}/>
        <p>{storeItemObject.product_name}</p>
        <p>{`$${storeItemObject.price}`}</p>
      </Link>
    </div>
  )
}

export default MerchItem;