import { React, useEffect } from 'react';
import style from '../styles/Homepage.module.scss';
import { Link } from 'react-router-dom';
// Components
import HeroSection from '../components/HeroSection';
import HomepageShopCategory from '../components/HomepageShopCategory';

// Assets
import starFiendLogoWithText from '../assets/icons/star-fiend-logo-pc.png';
import MerchShowcase from '../components/MerchShowcase';

function Homepage() {

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className={style.homePage}>
      <HeroSection/>
      <main className={style.homePageMain}>
        <div>
          <img className={style.starFiendLogoWithText} src={starFiendLogoWithText} alt='Star Fiend logo'></img>
          <h2>Where Style Meets Quality</h2>
        </div>
        <section className={style.apparelSection}>
          <h2>New Merch</h2>
          <MerchShowcase/>
        </section>
        <section className={style.HomepageShopCategoriesSection}>
          <Link to='/store/shirts'><HomepageShopCategory shopCategory={'Stylish T-Shirts'} categoryImageURL={'leave-the-road-t-shirt-lrg.jpg'}/></Link>
          <Link to='/store/shoes'><HomepageShopCategory shopCategory={'Comfy Shoes'} categoryImageURL={'blue-and-white-shoe-lrg.jpg'}/></Link>
          <Link to='/store/accessories'><HomepageShopCategory shopCategory={'Fashionable Accessories'} categoryImageURL={'black-t-shirt-tan-hat-lrg.jpg'}/></Link>
        </section>
      </main>
      
    </div>
  )
}

export default Homepage;