import React from 'react';
import style from '../styles/MerchShowcaseItem.module.scss';
import { Link } from 'react-router-dom';

function MerchShowcaseItem(props) {
  const { productObject } = props;
  return (
    <Link to={`/store/item/${productObject.product_id}`} className={style.showcaseDiv}>
      <img className={style.showcaseImg} src={process.env.PUBLIC_URL + `/assets/images/${productObject.file_name}`} alt={productObject.product_name}/>
      <div className={style.showcaseItemDetails}>
        <p>{productObject.product_name}</p>
        <p>{`$ ${productObject.price}`}</p>
      </div>
      
    </Link>
  )
}
export default MerchShowcaseItem;