import React from 'react';
import {useEffect, useState } from 'react';
import MerchShowcaseItem from './MerchShowcaseItem';
import style from '../styles/MerchShowcase.module.scss';
import supabase from '../config/supabaseClient.js';
function MerchShowcase() {
  const [showcaseItems, setShowCaseItems] = useState([]);
  const [fetchError, setFetchError] = useState(null);

  useEffect(() => {
    getShowCaseItems();
  }, []);
  
  const showcaseItemsMap = showcaseItems.map(item => {
    return (
      <MerchShowcaseItem key={item.product_id} productObject={item}></MerchShowcaseItem>
      );
    }); 

  async function getShowCaseItems() {
    const { data, error } = await supabase
    .from('store')
    .select('*')
    .limit(10)
    .order('date_added', { ascending: false })
    if(error) {
      setFetchError('Could not fetch showcase items');
      setShowCaseItems(null);
    }
    if(data) {
      setFetchError(null);
      setShowCaseItems(data);
    }
  }

  return (
    <section className={style.showcaseDiv}>
      {showcaseItemsMap}
    </section>
  )
}

export default MerchShowcase;