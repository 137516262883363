/*  A page that shows information regarding a specific merchandise item in the store. It retrieves information from the database.
*   The database information is combined with information on the screen (item amount info, optional size info, etc) through a factory
*   function, and is then added into a currentCart array.
*/

import React from 'react';
import { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';

import style from '../styles/MerchPage.module.scss';

import backButtonImage from '../assets/icons/arrow-left-back-button.png';
import supabase from '../config/supabaseClient';

function MerchPage(props) {
  const [productData, setProductData] = useState([]);
  const [itemSize, setItemSize] = useState();
  const [itemAmount, setItemAmount] = useState(1);
  const [addCartButtonText, setAddCartButtonText] = useState('ADD TO CART');
  const [isDisabled, setIsDisabled] = useState(false);
  const [itemDepartment, setItemDepartment] = useState();
  const { currentCart, setCurrentCart } = props;
  // Get the passed in prop data
  let { productid } = useParams();
  productid= parseInt(productid);

  useEffect(() => {
    window.scrollTo(0, 0);
    getDesiredProduct();
  }, [itemDepartment]);

  const getDesiredProduct = async () => {
    const { data, error } = await supabase
    .from('store')
    .select()
    .eq('product_id', productid)
    .limit(1)
    .single()
    console.log(data);
    if(error) {
      console.log(error);
    } else {
      setProductData(data);
      setItemDepartment(data.department);
      initializeDefaultItemSize();
    }
  }

  function initializeDefaultItemSize() {
    if(itemDepartment === 'Shirt') {
      setItemSize('SM');
    }
    else if(itemDepartment === 'Shoes') {
      setItemSize('34 EU');
    }
  }

  function increaseItemAmount() {
    let incrementAmount = itemAmount + 1;
    setItemAmount(incrementAmount);
  }

  function decreaseItemAmount() {
    let decrementAmount = itemAmount;
    if((decrementAmount-1)<0) {
      decrementAmount = 0;
      setItemAmount(decrementAmount);
    } else {
      setItemAmount(decrementAmount-1);
    } 
  }

  function renderSizeOrNot() {
    if(productData.department === 'Shirt') {
      return (
        <div className={style.merchSizeOptions}>
          <p>Size: {itemSize}</p>
            <ul className={style.sizesList}>
              <li onClick={(e) => setItemSize(e.target.innerText)}><button>SM</button></li>
              <li onClick={(e) => setItemSize(e.target.innerText)}><button>MED</button></li>
              <li onClick={(e) => setItemSize(e.target.innerText)}><button>LRG</button></li>
              <li onClick={(e) => setItemSize(e.target.innerText)} ><button>XL</button></li>
              <li onClick={(e) => setItemSize(e.target.innerText)}><button>XXL</button></li>
            </ul>
        </div>
      );
    }
    if(productData.department === 'Shoes') {
      return (
        <div className={style.merchSizeOptions}>
          <p>Size: {itemSize}</p>
          <select name='size' id='size'>
            <option value='34' onClick={(e) => setItemSize(e.target.innerText)}>34 EU</option>
            <option value='34.5' onClick={(e) => setItemSize(e.target.innerText)}>34.5 EU</option>
            <option value='35' onClick={(e) => setItemSize(e.target.innerText)}>35 EU</option>
            <option value='35.5' onClick={(e) => setItemSize(e.target.innerText)}>35.5 EU</option>
            <option value='36' onClick={(e) => setItemSize(e.target.innerText)}>36 EU</option>
            <option value='36.5' onClick={(e) => setItemSize(e.target.innerText)}>36.5 EU</option>
            <option value='37' onClick={(e) => setItemSize(e.target.innerText)}>37 EU</option>
            <option value='37.5' onClick={(e) => setItemSize(e.target.innerText)}>37.5 EU</option>
            <option value='38' onClick={(e) => setItemSize(e.target.innerText)}>38 EU</option>
            <option value='38.5'onClick={(e) => setItemSize(e.target.innerText)}>38.5 EU</option>
            <option value='39' onClick={(e) => setItemSize(e.target.innerText)}>39 EU</option>
            <option value='39.5' onClick={(e) => setItemSize(e.target.innerText)}>39.5 EU</option>
            <option value='40' onClick={(e) => setItemSize(e.target.innerText)}>40 EU</option>
            <option value='40.5' onClick={(e) => setItemSize(e.target.innerText)}>40.5 EU</option>
            <option value='41' onClick={(e) => setItemSize(e.target.innerText)}>41 EU</option>
            <option value='41.5' onClick={(e) => setItemSize(e.target.innerText)}>41.5 EU</option>
            <option value='42' onClick={(e) => setItemSize(e.target.innerText)}>42 EU</option>
            <option value='42.5' onClick={(e) => setItemSize(e.target.innerText)}>42.5 EU</option>
            <option value='43' onClick={(e) => setItemSize(e.target.innerText)}>43 EU</option>
            <option value='43.5' onClick={(e) => setItemSize(e.target.innerText)}>43.5 EU</option>
            <option value='44' onClick={(e) => setItemSize(e.target.innerText)}>44 EU</option>
            <option value='44.5' onClick={(e) => setItemSize(e.target.innerText)}>44.5 EU</option>
          </select>
        </div>
      )
    }
  }

  function cartItemFactory() {
    let cartItemObject;
    if(productData.department === "Shirt" || productData.department === "Shoes") {
      let cartId = productData.product_id + itemSize + '';
      //console.log(cartId);
      return cartItemObject = {
        product_id: productData.product_id,
        product_cart_id: cartId,
        product_name: productData.product_name,
        product_size: itemSize,
        product_amount: itemAmount,
        product_filename: productData.file_name,
        product_price: productData.price
      }
    } else {
      let cartId = productData.product_id;
      return cartItemObject = {
        product_id: productData.product_id,
        product_cart_id: cartId,
        product_name: productData.product_name,
        product_amount: itemAmount,
        product_filename: productData.file_name,
        product_price: productData.price
      }
    }
  }

  function modifyCartAmount(cartItemObject) {
    // Find the index of the item, compare (if exists) if the item is a shirt, compare sizes, if same, add to existing amount, if not, add to array
    // If not a shirt, see if it exists in the array, if not, add to array, if it does, add to the existing amount
    for(let cartLooper = 0; cartLooper < currentCart.length; cartLooper++) {
      if(cartItemObject.product_id === currentCart[cartLooper].product_id) {
        if(cartItemObject.product_size === undefined) {
          // Not a shirt
          // Add to item amount
          console.log(`The item is right`);
        }
        else if(cartItemObject.product_size === currentCart[cartLooper].product_size && cartItemObject.product_size !== undefined){
          // Right shirt, right size
          // Add to item amount
          console.log(`The size is right`);
          console.log(`The size of the non shirt is ${cartItemObject.product_size} and the size of the cart item is ${currentCart[cartLooper].product_size}`);
        }
      }
      else if(cartLooper >= currentCart.length) {
        // Reached the end of the array, add new item to array
        console.log(`hi, the cartLooper is at ${cartLooper}`)
      }
    }
  }

  function addToCart(e) {
    timeoutButton();
    e.preventDefault();
    let updatedCart;
    let cartItemObject = cartItemFactory();
    let wasArrayAltered = false;
    // Check if the item has already been added to the array (based on itemSize and product_id), if it has, take the itemAmount and add it to that 
    // specified item's itemAmount
    for(let cartLooper = 0; cartLooper < currentCart.length; cartLooper++) {
      if(cartItemObject.product_id === currentCart[cartLooper].product_id) {
        if(cartItemObject.product_size === undefined) {
          // Not an item with a size
          // Add to item amount
          wasArrayAltered = true;
          let currentCartArray = [...currentCart];
          currentCartArray[cartLooper].product_amount = currentCartArray[cartLooper].product_amount + cartItemObject.product_amount;
          updatedCart = currentCartArray;
          setCurrentCart(updatedCart);
        }
        else if(cartItemObject.product_size === currentCart[cartLooper].product_size && cartItemObject.product_size !== undefined){
          // Same item, same size
          // Add to item amount
          wasArrayAltered = true;
          let currentCartArray = [...currentCart];
          currentCartArray[cartLooper].product_amount = currentCartArray[cartLooper].product_amount + cartItemObject.product_amount;
          updatedCart = currentCartArray;
          setCurrentCart(updatedCart);
        }
      }
    }
    
    if(wasArrayAltered === false) {
      let currentCartArray = [...currentCart];
      currentCartArray.push(cartItemObject);
      updatedCart = currentCartArray
      setCurrentCart(updatedCart);
    }
  }
  
  function timeoutButton() {
    setIsDisabled(true);
    setAddCartButtonText('ITEM ADDED TO CART');
    const timer = setTimeout(function() {
      setIsDisabled(false);
      setAddCartButtonText('ADD TO CART');
    }, 1000);
  }

  function changeButton(isDisabled) {
    if(isDisabled) {
      <button className={style.addToCartButton} disabled={isDisabled} onClick={(e) => addToCart(e)} type='button'>ADD TO CART</button>
    }
    else {
      return (
        <button className={style.addToCartButton} disabled={isDisabled} onClick={(e) => addToCart(e)} type='button'>ADD TO CART</button>
      )
    }
  }

  return (
    <div className={style.merchPage}>
        <main className={style.merchMain}>
          <div className={style.merchMainDiv}>
            <section className={style.merchMainDivImage}>
              <div className={style.merchPageBackLinks}>
              <Link to='/store/all'><img className={style.backButtonImage} src={backButtonImage} alt='Back To Store Page Button'/></Link>
              <Link to='/'>Home</Link>
              <p>/</p>
              <Link to='/store/all'>Store</Link>
            </div>
              <img className={style.merchImage} src={process.env.PUBLIC_URL + `/assets/images/${productData.file_name}`} alt={productData.product_name}/>
            </section>
            <section className={style.merchMainDivDetails}>
              <div className={style.merchMainText}>
                <h2>{productData.product_name}</h2>
                <p>{`$${productData.price}`}</p>
                <p>{productData.description}</p>
              </div>
              {renderSizeOrNot()}
              <div className={style.orderAmountDiv}>
                <button onClick={() => decreaseItemAmount()}>–</button>
                <p>{itemAmount}</p>
                <button onClick={() => increaseItemAmount()}>+</button>
              </div>
              <button className={style.addToCartButton} disabled={isDisabled} onClick={(e) => addToCart(e)} type='button'>{addCartButtonText}</button>
            </section>
          </div>
        </main>
    </div>
  );
}

export default MerchPage;