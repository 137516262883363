import { React, useEffect, useState } from 'react';
import style from '../styles/NavBar.module.scss';
import { motion, AnimatePresence } from "framer-motion";
import { Link } from 'react-router-dom';
import companyLogo from '../assets/icons/star-fiend-logo.png';
import accountIcon from '../assets/icons/account-icon-white.png';
import shoppingCartIcon from '../assets/icons/shopping-cart-icon-white.png';
import CartItem from './CartItem';
import CartMini from './CartMini';

function NavBar(props) {
  const [navIsPressed, setNavIsPressed] = useState(false);
  const [cartIsPressed, setCartIsPressed] = useState(false);
  const [cartAmount, setCartAmount] = useState(0);
  const [userName, setUserName] = useState();
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })
  //const [isLoggedIn, setIsLoggedIn] = useState(false);
  let { currentCart } = props;
  

  useEffect(() => {
    function updateScreenSize() {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    calculateCartItemAmount();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, [currentCart]);

  

  function getMobileLayout() {
    return (
      <div className={style.navBar}>
        <nav className={style.navBarMobileMainLinks}>
          <div className={style.hamburgerMenu} onClick={() => hideWhenOtherIsClicked('nav')}>
            <div className={style.hamburgerMenuLine}>&nbsp;</div>
            <div className={style.hamburgerMenuLine}>&nbsp;</div>
            <div className={style.hamburgerMenuLine}>&nbsp;</div>
          </div>
          <Link className={style.companyLogoDiv} to='/' onClick={() => hideWhenOtherIsClicked('link')}><img className={style.companyLogo} src={companyLogo} alt="Star Fiend Company Logo"></img></Link>
          <div className={style.accountLinks}> 
            <Link to='/login' onClick={() => hideWhenOtherIsClicked('link')}><img className={style.accountIcon} src={accountIcon} alt="Account Icon"></img></Link>
            <div className={style.navCartDiv}>
              {isCartFilledWithAnything()}
              <Link to='/cart' onClick={() => hideWhenOtherIsClicked('cart')}><img className={style.accountIcon} src={shoppingCartIcon} alt="Shopping Cart Icon"></img></Link>
            </div>
            
          </div>
        </nav>
        <AnimatePresence>
          {navIsPressed && (
            <motion.ul className={style.navBarList}
            initial={{opacity: 0, x:-200}}
            animate={{opacity: 1, x:0}}
            transition={{ease: "linear"}}
            exit={{opacity: 0, x:-200}}>
            <li onClick={() => setNavIsPressed(!navIsPressed)}><Link to='/'>Home</Link></li>
            <li onClick={() => setNavIsPressed(!navIsPressed)}><Link to='/company'>Our Company</Link></li>
            <li onClick={() => setNavIsPressed(!navIsPressed)}><Link to='/store/all'>Store</Link></li>
            {/*<li onClick={() => setNavIsPressed(!navIsPressed)}><Link to='/contact'>Contact</Link></li>*/}
          </motion.ul>  
          )}
        </AnimatePresence>
      </div>
    );
  }

  function getDesktopLayout() {
    return (
    <nav className={style.navBar}>
        <div className={style.navBarDesktopMainLinks}>
          <ul className={style.navBarDesktopList}>
            <li><Link className={style.companyLogoDiv} to='/' onClick={() => hideWhenOtherIsClicked('link')}><img className={style.companyLogo} src={companyLogo} alt="Star Fiend Company Logo"></img></Link></li>
            <li onClick={() => setNavIsPressed(!navIsPressed)}><Link to='/'>Home</Link></li>
            <li onClick={() => setNavIsPressed(!navIsPressed)}><Link to='/company'>Our Company</Link></li>
            <li onClick={() => setNavIsPressed(!navIsPressed)}><Link to='/store/all'>Store</Link></li>
            {/*<li onClick={() => setNavIsPressed(!navIsPressed)}><Link to='/contact'>Contact</Link></li>*/}
          </ul>
          
        </div>
        <div className={style.accountLinks}> 
          <Link to='/login' onClick={() => hideWhenOtherIsClicked('link')}><img className={style.accountIcon} src={accountIcon} alt="Account Icon"></img></Link>
          <div className={style.navCartDiv}>
            {isCartFilledWithAnything()}
            <Link to='/cart' onClick={() => hideWhenOtherIsClicked('cart')}><img className={style.accountIcon} src={shoppingCartIcon} alt="Shopping Cart Icon"></img></Link>
          </div>
        </div>
      </nav>
    );
  }

  function getLayout() {
    if(screenSize.width < 1024) {
      return getMobileLayout();
    } else {
      return getDesktopLayout();
    }
  }

  function hideWhenOtherIsClicked(whatWasClickedOn) {
    if(whatWasClickedOn === 'nav') {
      // Check to see if cart is showing, if it is, toggle it
      if(cartIsPressed === true) {
        // Hide the cart, show nav links
        setCartIsPressed(!cartIsPressed);
        setNavIsPressed(!navIsPressed);
        
      }
      else {
        // If no cart, toggle nav
        setNavIsPressed(!navIsPressed);
      }
    }
    else if(whatWasClickedOn === 'cart') {
      if(navIsPressed === true) {
        // Hide nav, show cart
        setNavIsPressed(!navIsPressed);
        setCartIsPressed(!cartIsPressed);
      }
      else {
        // If no nav, toggle cart
        setCartIsPressed(!cartIsPressed);
      }
    }
    else {
      // Hide everything
      setCartIsPressed(false);
      setNavIsPressed(false);
    }
  }

  function calculateCartItemAmount() {
    let currentAmountTotal = 0;
    for(let currentItem = 0; currentItem < currentCart.length; currentItem++) {
      currentAmountTotal = currentAmountTotal + currentCart[currentItem].product_amount;
    }
    setCartAmount(currentAmountTotal);
  }  

  function isCartFilledWithAnything() {
    if(currentCart.length > 0) {
      return (
        <Link className={style.navCartAmountParaLink} to='/cart'><p className={style.navCartAmountPara}>{cartAmount}</p></Link>
      );
    }
  }

  return (
    <header>
      {getLayout()}
    </header>
    
  )
}

export default NavBar;