import React from 'react'
import style from '../styles/HomepageShopCategory.module.scss';

/*  
  This component is for the main shop categories which are shown on the Homepage.
  Categories such as apparel, shirts, pants, etc. Each component has a dark gradient
  overlay that goes from top right to bottom left, and the bottom left is where
  the component's category/text is

*/

function HomepageShopCategory(props) {
  const { shopCategory, categoryImageURL } = props; 
  return (
    <section className={style.shopCategory}>
      <div className={style.shopCategoryText}>
        <h2>{shopCategory}</h2>
        <p>View Our Full Selection</p>
      </div>
      <div className={style.HomepageShopCategoryOverlay}>
      </div>
      <img className={style.shopCategoryImage} src={require(`../assets/images/${categoryImageURL}`)}/>
      
    </section>
  )
}

export default HomepageShopCategory