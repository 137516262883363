import React, { useContext } from 'react';
import style from '../styles/Login.module.scss';
import { useState, useEffect } from 'react';
import supabase from '../config/supabaseClient';
import { UserContext } from '../App';
import { Link } from 'react-router-dom';

function Login() {
  const [userEmail, setUserEmail] = useContext(UserContext);
  const [userPassword, setUserPassword] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  async function validateLogin() {
    // Make sure there's an existing account with the email/password
    const { data, error } = await supabase.auth.signInWithPassword({
      email: userEmail,
      password: userPassword
    })
    if(error) {
      alert('Error: Invalid username/password');
      console.log(error);
      console('nah.');
    } else {
      console.log(data);
      console('successful.');
    }
    
  }

  return (
    <div className={style.loginPage}>
      
      <form className={style.loginForm} action='/account' method='POST'>
        <h1>Login</h1>
        <div className={style.formMain}>
          <div className={style.loginLabelAndInput}>
            <label className={style.loginFormLabel} htmlFor='login'>Username</label>
            <input className={style.loginFormInput} id='login' name='userEmail' type='text' onChange={(e) => setUserEmail(e.target.value)} required></input>
          </div>
          <div className={style.loginLabelAndInput}>
            <label className={style.loginFormLabel} htmlFor='password'>Password</label>
            <input className={style.loginFormInput} id='password' name='userPassword' type='password' onChange={(e) => setUserPassword(e.target.value)} required></input>
          </div>
          <div className={style.loginButtonsDiv}>
            <button type='submit' onClick={() => validateLogin}>Sigh In</button>
            <Link to=''></Link><p>New?  Create Account Here</p>
          </div>
        </div>
        
      </form>
    </div>
  )
}

export default Login;