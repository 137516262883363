import {React, useEffect } from 'react';
import style from '../styles/PrivacyPolicy.module.scss';

function PrivacyPolicy() {

  useEffect(() => {
    window.scrollTo(0,0);
  });

  return (
    <div className={style.privacyPolicyDiv}>
      <h2>Privacy Policy</h2>
      <p>Your data is your own, I don't track that.</p>
      <p>
        This is just a practice website. I may in the future use cookies to remove that repeating
        popup to for now, as of 1/1/2024, there are no cookies used for the site. 
      </p>
      <p>As always, I appreciate you checking out this humble site!</p>
    </div>
  )
}

export default PrivacyPolicy